import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import BestFacilities from '../../components/shared/BestFacilities';
import about1 from '../../resources/themeContent/images/theme-images/about1.jpg';
import signature from '../../resources/themeContent/images/theme-images/signature.png';


const About = () => {
    const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);

    return (
        <>


            <Helmet>
                <title>{siteTitle} - About Us</title>
                <meta name="description" content={siteTitle + " - About us page"} />
                <meta name="keywords" content="About us"></meta>
            </Helmet>

            <SiteBreadcrumb title="About Us" />

            <section className="about-area ptb-60">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <h2>About Our Store</h2>
                                <p>
                                    At Gaapalelwe Business Enterprise, we're committed to building string relationships with our clients.
                                    Our core values of honesty, integrity, and community engagement drive our mission.
                                    We believe in giving back and making a difference
                                </p>
                                <h2>Our product range</h2>
                                <p>
                                    Our product range
                                    Our company supplies a comprehensive range of stationery and office supplies, including:
                                    ,Art and School Supplies
                                    ,Batteries
                                    ,Books, Carbon, and Envelopes
                                    ,Pens, carbon, and self-carbonized stationery
                                    ,Computer Accessories
                                    ,Desk Accessories
                                    ,Filing
                                    ,General Office Stationery and Supplies
                                    ,Glues and Adhesives
                                    ,Office Labels
                                    ,Office Machines, Trimmers, and Consumables
                                    ,Printers and printing consumables
                                    ,Printing
                                    ,Promotional gifts and branding
                                    ,Rubber Stamps
                                    ,Staplers and punches
                                    ,Tapes and Dispensers
                                    ,Technical Writing and Correction Instruments</p>

                                <div className="signature mb-0">
                                    <img src={signature} alt="image" />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-image">
                                <img src={about1} className="about-img1" alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <BestFacilities />

        </>
    );

}

export default About;
